import React, { useState } from 'react';
import styled from 'styled-components';

import { IPages } from 'apiFolder/directus-main';

import { H1, Paragraph } from 'components/common/Styles';

import Footer from 'components/Footer/Footer';
import { ContentArticle } from 'components/Content';
import Seo from 'components/seo';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import GoogleMap from '../components/common/ContactUsMap';

interface IContactUs {
	pageContext: {
		contactUsMarkupShcema: IPages;
	};
}

const ContactUs: React.FC<IContactUs> = ({ pageContext }) => {
	const [isMenuOpen, toggleMenu] = useState(false);

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};
	return (
		<>
			<Seo
				title='Contact Us: Reach out with what you need'
				description='No matter what you need, our friendly team are here to support your every need. Contact us with your enquiry and we will get back to you ASAP.'
				markupSchema={pageContext.contactUsMarkupShcema.markup_schema}
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle isOpen={isMenuOpen}>
						<Wrapper>
							<Information>
								<div>
									<H1>Contact us</H1>
									<Paragraph>Head office: 2/11 York Street, Sydney NSW 2000</Paragraph>
									<Paragraph>Questions about our product or services?</Paragraph>
									<Paragraph>Call us Monday - Friday 9am - 6pm AEST</Paragraph>
									<Paragraph>
										<Hovered>
											<a href='tel:+610290686658'>(02) 9068 6658</a>.
										</Hovered>
									</Paragraph>
								</div>
								<MapWrapper>
									<GoogleMap />
								</MapWrapper>
							</Information>
							<Paragraph>
								Please note, BookPhysio.com cannot answer questions or provide guidance about specific
								injury or treatment options for your case.
							</Paragraph>
							<Paragraph>
								To make a booking with a physiotherapist in your area, use our free physio near me page
								to make an appointment.
							</Paragraph>
							<Paragraph>
								For other questions or to provide feedback, email
								<HoveredInline>
									<a href='mailto:team@bookphysio.com'>team@bookphysio.com</a>.
								</HoveredInline>
							</Paragraph>
						</Wrapper>
					</ContentArticle>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};
const Information = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 80px 0;
	position: relative;
	@media (max-width: 640px) {
		flex-direction: column;
		margin: 50px 0;
	}
`;

const MapWrapper = styled.div`
	flex: 0 0 250px;
	width: 250px;
	height: 250px;
	position: relative;

	@media (max-width: 640px) {
		width: 100%;
	}
`;

const Wrapper = styled.div`
	max-width: 700px;
	margin: 0 auto;
`;
const Hovered = styled(Paragraph)`
	& a {
		color: ${({ theme }) => theme.palette.purp};
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;

const HoveredInline = styled(Hovered)`
	display: inline-block;
	margin: 0 5px;
`;

export default ContactUs;
