import React, { useEffect, useMemo, useState } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import circulIcon from 'images/icons/circleIcon.svg';
import circleBlueIcon from 'images/icons/circleBlueIcon.svg';
import { IClinic, ISuburb } from 'templates/booking';

export const AUSTRALIA_CENTER = {
	lat: -28.6274942,
	lng: 134.2125589,
};

interface ILocation {
	lat: number;
	lng: number;
}

interface IMapContainer {
	clinics: IClinic[];
	suburb: ISuburb;
}

const ICON_SIZE = 16;

const MapContainerFC: React.FC<IMapContainer> = ({ suburb }) => {
	const [mapCenter, setMapCenter] = useState<ILocation>(suburb?.location || AUSTRALIA_CENTER);
	const [mapZoom, setMapZoom] = useState<number>(suburb ? 13 : 5);

	useEffect(() => {
		if (!suburb) {
			setMapCenter(AUSTRALIA_CENTER);
			setMapZoom(5);
			return;
		}
		if (suburb.location) {
			setMapCenter(suburb.location);
			setMapZoom(13);
		}
	}, [suburb]);

	const hadleMapClick = () => {
		if (suburb) {
			setMapZoom(mapZoom >= 17 ? +mapZoom - 1 : 13);
			return;
		}
		setMapZoom(+mapZoom + 1);
	};

	const loc = { lat: -33.865312, lng: 151.205217 };

	const map = useMemo(() => {
		return (
			<Map
				google={google}
				containerStyle={{
					position: 'static',
					width: '100%',
					height: '100%',
				}}
				style={{
					width: '100%',
					height: '100%',
				}}
				center={loc}
				initialCenter={loc}
				zoom={18}
				disableDefaultUI={true}
				onClick={hadleMapClick}
			>
				<Marker
					key='suburbCenter'
					title={`${suburb?.label || 'Australia'} center`}
					position={loc}
					onClick={() => setMapCenter(loc || AUSTRALIA_CENTER)}
					icon={{
						anchor: new google.maps.Point(ICON_SIZE * 0.8, ICON_SIZE * 0.8),
						url: circleBlueIcon,
						scaledSize: new google.maps.Size(ICON_SIZE * 0.8 * 2, ICON_SIZE * 0.8 * 2),
					}}
				/>
				<Marker
					title={'2/11 York Street, Sydney NSW 2000'}
					position={loc}
					icon={{
						anchor: new google.maps.Point(ICON_SIZE, ICON_SIZE),
						url: circulIcon,
						scaledSize: new google.maps.Size(ICON_SIZE * 2, ICON_SIZE * 2),
					}}
					zIndex={1}
				></Marker>
			</Map>
		);
	}, [suburb, mapZoom, mapCenter]);
	return map;
};

export default GoogleApiWrapper({
	apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY!,
})((MapContainerFC as unknown) as IMapContainer);
